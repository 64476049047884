import React, {useState, useEffect} from 'react';

const getFormattedDate = () => {
    const today = new Date();

    const year = String(today.getFullYear()).slice(-2);
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    return [`${year}${month}${day}`, `${day}.${month}.${year}`];
}

const getFormattedTime = (timestamp) =>{
    const dateString = timestamp;
    const date = new Date(dateString);

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
}

const API_URL = `https://www.tagesschau.de/api2/newsfeed-101~_date-${getFormattedDate()[0]}.json`;

const DO_NOT_SHOW = [
    "tagesschau in 100 Sekunden",
    "Die Wetteraussichten",
    "Die Nachrichten auf tagesschau24",
    "Report Mainz",
    "Sportschau",
    "Tagesthemen"
]

const News = () => {
    const [news, setNews] = useState([]);
    const [isError, setIsError] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    const fetchNews = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(API_URL);
            const newsData = await response.json();
            const {news} = newsData;
            setNews(news);
            setIsLoading(false)
        } catch (err) {
            setIsError(err);
        }
    }

    useEffect(() => {
        fetchNews();
    }, []);

    useEffect(()=>{
        let refresh = setInterval(()=>{
            fetchNews()
        },600000)
        return ()=> clearInterval(refresh)
    }, [news])

    if(isLoading) return <h3>Laden ...</h3>
    if(isError) return <h1>Fehler beim laden der News aufgetreten</h1>

    return <>
        <h3>tagesschau news {getFormattedDate()[1]}</h3>
        <ul className="users">
            {news.map((article) => {
                const {sophoraId, title, date, detailsweb, teaserImage} = article
                let url = ""
                if(DO_NOT_SHOW.includes(title)) return null;
                if ('teaserImage' in article) {
                    if ('klein1x1' in teaserImage) {
                        const {klein1x1} = teaserImage;
                        const {imageurl} = klein1x1;
                        url = imageurl
                    }
                }

                return <li key={sophoraId}>
                    <div>
                        <div>{getFormattedTime(date)}</div>
                        {url && <img src={url}/>}
                        <h5>{title}</h5>
                        {detailsweb && <a href={detailsweb} target="_blank">details</a>}
                    </div>
                </li>

            })}
        </ul>
    </>;
};

export default News;
